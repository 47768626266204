export default {
    methods: {

        exportTableAsCSV() {
            const rows = this.$store.getters.getSites.map(site => [
              site.siteID,
              `"${site.styleName.replace(/"/g, '""')}"`, // Escape double quotes by doubling them
              site.clientID,
              `${process.env.VUE_APP_APP_ROOT}/home/${site.siteID}`,
              site.isActivated,
              this.formatTimestamp(site, "activationDate"),
              this.formatTimestamp(site, "lastLoginTime"),
              this.formatLastTouchscreenHeartbeatTimestamp(site),
              this.formatLastTouchscreenHeartbeatFullscreen(site),
              site.documentCount,
              site.siteOwner,
              this.siteRating(site).issuesCount,
            ]);
      
            const csvContent = [
              ["Site ID", "Organization Name", "Client Data", "Site Link", "Site Active", "Activation Date", "Last Login", "Last Touchscreen Heartbeat", "Site Fullscreen", "Document Count", "Owner", "Site Rating"],
              ...rows
            ].map(e => e.join(",")).join("\n");
      
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "sites.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          },
    },
};