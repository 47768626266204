import { customerSuccessManagers } from "@/utils/constants";

export default {
  methods: {
    sortSites() {
      let filteredSites = this.filteredSites;
      // sort by categoryCount or entryCount, either by ascending or descending order depending on what user selected
      filteredSites.sort((a, b) => {
        switch (this.keyToSortBy) {
          case "document":
            return this.sortingOrder === "ascending"
              ? a.documentCount - b.documentCount
              : b.documentCount - a.documentCount;
          case "lastLogin":
            // lastLoginTime is undefined when labeled "N/A"
            if (a.lastLoginTime === undefined) return 1; // always put N/A at bottom
            if (b.lastLoginTime === undefined) return -1; // always put N/A at bottom
            return this.sortingOrder === "ascending"
              ? b.lastLoginTime - a.lastLoginTime
              : a.lastLoginTime - b.lastLoginTime;
          case "activationDate":
            // activationDate is undefined or null when labeled "N/A"
            if (!a.activationDate) return 1; // always put N/A at bottom
            if (!b.activationDate) return -1; // always put N/A at bottom
            return this.sortingOrder === "ascending"
              ? b.activationDate - a.activationDate
              : a.activationDate - b.activationDate;
          case "lastTouchscreenHeartbeatTimestamp":
            // lastTouchscreenHeartbeat is null when labeled "N/A"
            if (a.lastTouchscreenHeartbeat === null) return 1; // always put N/A at bottom
            if (b.lastTouchscreenHeartbeat === null) return -1; // always put N/A at bottom
            return this.sortingOrder === "ascending"
              ? b.lastTouchscreenHeartbeat.timestamp -
                  a.lastTouchscreenHeartbeat.timestamp
              : a.lastTouchscreenHeartbeat.timestamp -
                  b.lastTouchscreenHeartbeat.timestamp;
          case "lastTouchscreenHeartbeatFullscreen": {
            // lastTouchscreenHeartbeat is null when labeled "N/A"
            if (a.lastTouchscreenHeartbeat === null) return 1; // always put no heartbeat at bottom
            if (b.lastTouchscreenHeartbeat === null) {
              return -1; // always put no heartbeat at bottom
            }
            const aValue = a.lastTouchscreenHeartbeat.fullscreen ? 1 : 0;
            const bValue = b.lastTouchscreenHeartbeat.fullscreen ? 1 : 0;
            return this.sortingOrder === "Fullscreen"
              ? bValue - aValue
              : aValue - bValue;
          }
          case "rating": {
            const ratingA = this.siteRating(a).issuesCount;
            const ratingB = this.siteRating(b).issuesCount;
            return this.sortingOrder === "ascending"
              ? ratingA - ratingB
              : ratingB - ratingA;
          }
          case "siteOwner": {
            return this.sortingOrder === customerSuccessManagers[0] ? a.siteOwner.localeCompare(b.siteOwner) : b.siteOwner.localeCompare(a.siteOwner);
          }
          default:
            return b.isActivated - a.isActivated;
        }
      });
      return filteredSites;
    },
  },
};
