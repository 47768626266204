export default {
  methods: {
    siteRating(site) {
      const LOW_DOCUMENT_COUNT = 50;
      const MEDIUM_DOCUMENT_COUNT = 100;
      const FLAG_EMOJI = "🚩";
      const ALL_GOOD_EMOJI = "🥳";
      let rating = { issuesCount: 0, emojiString: "" };

      if (site.documentCount < LOW_DOCUMENT_COUNT) {
        rating.issuesCount += 2;
        rating.emojiString += FLAG_EMOJI.repeat(2);
      } else if (site.documentCount < MEDIUM_DOCUMENT_COUNT) {
        rating.issuesCount++;
        rating.emojiString += FLAG_EMOJI;
      }

      if (this.fiftyDaysSinceLastLogin(site)) {
        rating.issuesCount++;
        rating.emojiString += FLAG_EMOJI;
      }

      const touchscreenHeartbeat = site.lastTouchscreenHeartbeat;
      // add flag if no touchscreen heartbeat or if last heartbeat wasn't fullscreen
      if (!touchscreenHeartbeat || touchscreenHeartbeat.timestamp === null) {
        rating.issuesCount++;
        rating.emojiString += FLAG_EMOJI;
      }
      if (touchscreenHeartbeat && !touchscreenHeartbeat.fullscreen) {
        rating.issuesCount++;
        rating.emojiString += FLAG_EMOJI;
      }

      if (rating.issuesCount === 0) {
        rating.emojiString = ALL_GOOD_EMOJI;
      }
      return rating;
    },
    fiftyDaysSinceLastLogin(site) {
      if (!site.lastLoginTime) {
        return false;
      }
      const diff =
        (Date.now() - new Date(site.lastLoginTime)) / (1000 * 60 * 60 * 24);
      return diff > 50;
    },
  },
};
