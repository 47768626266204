export default {
  methods: {
    /**
     * Updates a siteID's info (such as name or data it is attached to) and then re-fetches sites from database
     *
     * @param {Number} siteID the id of the site we want to edit
     * @param {String} attributeLabel the front-facing name of the attribute we will edit (for success/error messages)
     * @param {Object} attributes an object mapping attribute name to value (ie. { styleName: 'New Style Name' })
     */
    updateSiteInfo(siteID, attributeLabel, attributes) {
      this.$api
        .patch(`/siteID/${siteID}/`, attributes)
        .then(() => {
          alert(
            `The ${attributeLabel} for site ${siteID} has been successfully changed`
          );
          this.$emit("refetch");
        })
        .catch(() => {
          alert(
            `Error occurred while updating site ${attributeLabel}. Please contact support.`
          );
        });
    }
  }
};
